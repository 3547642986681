





















import { Component } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component
export default class BigNumbersGroupComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  created(): void {
    this.setDataConfig();
  }

  // eslint-disable-next-line class-methods-use-this
  private updated(): void {
    // hack to trigger animation
    window.scrollTo({ top: window.scrollY - 1 });
    window.scrollTo({ top: window.scrollY + 1 });
  }
}
